import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Article from '../components/Article';
import RichText from '../components/RichText';
import { graphql } from 'gatsby';

export const pageQuery = graphql`
  {
    contentfulArticle(slug: { eq: "weine" }) {
      name
      text {
        json
      }
      image {
        fluid(maxWidth: 1200) {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
`;

export default props => (
  <Layout>
    <SEO
      description="Im Rebgarten und Keller gepflegt und ausgebaut, von den Sulzberger Rebläusen."
      title={props.data.contentfulArticle.name}
      keywords={[`Rebberg Sulzberg`, `Weine`]}
    />
    <Article
      title={props.data.contentfulArticle.name}
      image={props.data.contentfulArticle.image}
    >
      <RichText document={props.data.contentfulArticle.text.json} />
    </Article>
  </Layout>
);
